import { micromark } from 'micromark'
import { gfm, gfmHtml } from 'micromark-extension-gfm'
import DOMPurify from 'dompurify'

export function markdownToHtml(markdown) {
    if (!markdown) return ''

    const html = micromark(markdown, {
        extensions: [gfm()],
        htmlExtensions: [gfmHtml()]
    })

    return DOMPurify.sanitize(html, {
        USE_PROFILES: { html: true }
    })
}
