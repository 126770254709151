import { Controller } from "@hotwired/stimulus"
import { markdownToHtml } from "@/utils/markdown"
import * as Turbo from "@hotwired/turbo"

/**
 * Controller for handling chat interactions with an AI assistant.
 * Manages message display, streaming responses, and user input.
 */
export default class extends Controller {
    static targets = ['chat', 'messages', 'input', 'submit'];
    static values = {
        csrf: String,
        uuid: String
    }

    connect() {
        this.isProcessing = false;
        this.abortController = null;
        this.initializeChat();
    }

    /**
     * Initialize the chat interface on load
     */
    initializeChat() {
        // On initial load, scroll to bottom immediately
        if (this.hasMessagesTarget && this.messagesTarget.children.length > 0) {
            this.scrollToBottom({ smooth: false, force: true });
        }
    }

    /**
     * Handle keyboard events in the input field
     * @param {KeyboardEvent} event - The keyboard event
     */
    handleKeyPress(event) {
        // Submit on Enter (without Shift)
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            this.sendMessage(event);
        }
    }

    /**
     * Check if user is at the scroll end (bottom) of the chat container
     * @returns {boolean} - True if at the end of scroll
     */
    isAtScrollEnd() {
        if (!this.hasChatTarget) return true;
        
        const chat = this.chatTarget;
        const threshold = 64;
        
        return (chat.scrollHeight - chat.scrollTop - chat.clientHeight) <= threshold;
    }

    /**
     * Scroll to the bottom of the chat container
     * @param {Object} options - Scrolling options
     * @param {boolean} options.smooth - Whether to use smooth scrolling
     * @param {boolean} options.force - Whether to force scrolling regardless of current position
     */
    scrollToBottom({ smooth = true, force = false } = {}) {
        if (!this.hasChatTarget) return;
        
        if (this.isAtScrollEnd() || force) {
            this.chatTarget.scrollTo({
                top: this.chatTarget.scrollHeight,
                behavior: smooth ? 'smooth' : 'auto'
            });
        }
    }

    /**
     * Send a chat message and process the response
     * @param {Event} event - The triggering event
     */
    async sendMessage(event) {
        event.preventDefault();
        
        // Prevent multiple submissions while processing
        if (this.isProcessing) return;

        const query = this.inputTarget.value.trim();
        if (!query) return;

        try {
            // Set processing state
            this.setProcessingState(true);
            
            // Add user message to the chat
            this.addMessage('user', query);
            this.inputTarget.value = '';
            this.scrollToBottom({ force: true });
            
            // Message state tracking
            let systemMessageId = null;
            let assistantMessageId = null;
            let assistantContent = '';
            
            const response = await this.fetchChatResponse(query);
            
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            
            await this.processResponseStream(response, {
                onSystemMessage: (content) => {
                    if (systemMessageId) {
                        this.updateMessage(systemMessageId, content);
                    } else {
                        systemMessageId = this.addMessage('system', content);
                        this.scrollToBottom({ force: true });
                    }
                },
                onAssistantMessage: (content) => {
                    // When we get assistant content, remove any system messages
                    if (systemMessageId) {
                        this.removeMessage(systemMessageId);
                        systemMessageId = null;
                    }
                    
                    // Initialize or update assistant message
                    if (!assistantMessageId) {
                        assistantMessageId = this.addMessage('assistant', '', true);
                    }
                    
                    // Accumulate content and update display
                    assistantContent += content;
                    this.updateStreamingMarkdown(assistantMessageId, assistantContent);
                },
                onTurboStream: (stream) => {
                    Turbo.renderStreamMessage(stream);
                },
                onCompletion: () => {
                    if (assistantMessageId) {
                        this.finalizeMessage(assistantMessageId, assistantContent);
                    }
                }
            });
        } catch (error) {
            console.error('Error:', error);
            if (error.name !== 'AbortError') {
                this.handleChatError(assistantMessageId, systemMessageId);
            }
        } finally {
            // Clean up
            this.abortController = null;
            this.setProcessingState(false);
        }

        this.scrollToBottom();
    }

    /**
     * Set the processing state and update UI accordingly
     * @param {boolean} isProcessing - Whether a request is currently being processed
     */
    setProcessingState(isProcessing) {
        this.isProcessing = isProcessing;
        
        if (isProcessing) {
            this.element.classList.add('is-processing');
        } else {
            this.element.classList.remove('is-processing');
        }
    }

    /**
     * Fetch the chat response from the server
     * @param {string} query - The user's query text
     * @returns {Promise<Response>} - Fetch response promise
     */
    fetchChatResponse(query) {
        const formData = new FormData();
        formData.append('query', query);
        
        // Create a new AbortController for this request
        this.abortController = new AbortController();
        
        return fetch(`/insights/chat/${this.uuidValue}/query/`, {
            method: 'POST',
            headers: {
                'X-CSRFToken': this.csrfValue
            },
            body: formData,
            signal: this.abortController.signal
        });
    }

    /**
     * Process the streaming response from the server
     * @param {Response} response - The fetch response object
     * @param {Object} callbacks - Callback functions for different message types
     */
    async processResponseStream(response, callbacks) {
        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');
        let buffer = '';
        
        while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            
            // Decode the stream data
            buffer += decoder.decode(value, { stream: true });
            
            // Process complete server-sent events
            const lines = buffer.split('\n\n');
            buffer = lines.pop(); // Keep incomplete chunk for next iteration
            
            for (const line of lines) {
                if (!line.trim() || !line.startsWith('data:')) continue;
                
                try {
                    const data = JSON.parse(line.slice(5));
                    this.handleStreamEvent(data, callbacks);
                } catch (e) {
                    console.error('Error parsing SSE data:', e);
                }
            }
        }
    }

    /**
     * Handle individual stream events based on their type
     * @param {Object} data - The parsed SSE data
     * @param {Object} callbacks - Callback functions for different message types
     */
    handleStreamEvent(data, callbacks) {
        if (data.type === 'message') {
            if (data.role === 'system' && callbacks.onSystemMessage) {
                callbacks.onSystemMessage(data.content);
            } 
            else if (data.role === 'assistant' && callbacks.onAssistantMessage) {
                callbacks.onAssistantMessage(data.content);
            }
        } 
        else if (data.type === 'turbo-stream' && data.stream && callbacks.onTurboStream) {
            callbacks.onTurboStream(data.stream);
        }
        else if (data.type === 'event' && data.name === 'done' && callbacks.onCompletion) {
            callbacks.onCompletion();
        }
    }

    /**
     * Handle errors in the chat process
     * @param {string|null} assistantMessageId - ID of the assistant message, if exists
     * @param {string|null} systemMessageId - ID of the system message, if exists
     */
    handleChatError(assistantMessageId, systemMessageId) {
        const errorMessage = "Sorry, there was an error processing your request.";
        
        if (!assistantMessageId) {
            this.addMessage('assistant', errorMessage);
        } else {
            this.updateMessage(assistantMessageId, errorMessage);
        }
        
        // Remove any system messages
        if (systemMessageId) {
            this.removeMessage(systemMessageId);
        }
    }

    /**
     * Add a new message to the chat
     * @param {string} role - The role of the message sender (user/assistant/system)
     * @param {string} content - The message content
     * @param {boolean} isLoading - Whether to display a loading state
     * @returns {string} - The unique ID of the created message
     */
    addMessage(role, content, isLoading = false) {
        const messageId = this.generateMessageId();
        const sanitizedContent = this.sanitizeContent(content);
        let renderedContent = isLoading ? '' : this.renderMarkdown(sanitizedContent);
        
        const messageHtml = `
            <div class="chatbot-message ${role}" data-message-id="${messageId}">
                <div class="chatbot-message-content ${isLoading ? 'loading' : ''}">
                    ${renderedContent}
                </div>
            </div>
        `;
        this.messagesTarget.insertAdjacentHTML('beforeend', messageHtml);
        this.scrollToBottom();
        return messageId;
    }
    
    /**
     * Generate a unique message ID
     * @returns {string} - A unique message identifier
     */
    generateMessageId() {
        return `msg-${Date.now()}-${Math.random().toString(36).substring(2, 9)}`;
    }
    
    /**
     * Remove a message from the chat
     * @param {string} messageId - The ID of the message to remove
     */
    removeMessage(messageId) {
        const message = this.messagesTarget.querySelector(`[data-message-id="${messageId}"]`);
        if (message) {
            message.remove();
        }
    }
    
    /**
     * Sanitize message content
     * @param {string} content - The raw message content
     * @returns {string} - Sanitized content
     */
    sanitizeContent(content) {
        return content.trim();
    }
    
    /**
     * Render markdown content as HTML
     * @param {string} content - The markdown content
     * @returns {string} - HTML representation of the markdown
     */
    renderMarkdown(content) {
        return markdownToHtml(content);
    }

    /**
     * Update an existing message
     * @param {string} messageId - The ID of the message to update
     * @param {string} content - The new content
     */
    updateMessage(messageId, content) {
        const messageElement = this.messagesTarget.querySelector(`[data-message-id="${messageId}"]`);
        if (!messageElement) return;
        
        const messageContent = messageElement.querySelector('.chatbot-message-content');
        if (messageContent) {
            messageContent.classList.remove('loading');
            messageContent.innerHTML = this.renderMarkdown(this.sanitizeContent(content));
        }
        
        this.scrollToBottom();
    }
    
    /**
     * Update a streaming message with markdown content
     * @param {string} messageId - The ID of the message
     * @param {string} content - The current accumulated content
     */
    updateStreamingMarkdown(messageId, content) {
        const message = this.messagesTarget.querySelector(`[data-message-id="${messageId}"] .chatbot-message-content`);
        if (message) {
            message.innerHTML = this.renderMarkdown(this.sanitizeContent(content));
            message.classList.add('loading');
        }
        this.scrollToBottom();
    }
    
    /**
     * Finalize a message when streaming is complete
     * @param {string} messageId - The ID of the message
     * @param {string} content - The final content
     */
    finalizeMessage(messageId, content) {
        const message = this.messagesTarget.querySelector(`[data-message-id="${messageId}"] .chatbot-message-content`);
        if (message) {
            message.classList.remove('loading');
            message.innerHTML = this.renderMarkdown(this.sanitizeContent(content));
        }
        this.scrollToBottom();
    }

    // New method for handling button clicks
    submit(event) {
        event.preventDefault();
        
        if (this.isProcessing) {
            // Button acts as cancel when processing
            this.cancelRequest();
        } else {
            // Normal submit behavior
            this.sendMessage(event);
        }
    }

    // New method for canceling requests
    cancelRequest() {
        if (this.abortController) {
            this.abortController.abort();
            this.abortController = null;
            // Feedback will be handled in the catch block of sendMessage
        }
    }
}